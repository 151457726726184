@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

$sidebar-docked-width: 320px;
$sidebar-header-height: 96px;

.root {
}

.overlay {
}

.content {
  z-index: 0;

  @include fullHeight;
}

.contentContainer {
  composes: container-fluid from global;

  @include fullHeight;
}

.sidebar {
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;

  .root.docked & {
    width: $sidebar-docked-width;
  }
}

.sidebarHeader {
  background-color: $black;
  position: fixed;
  height: $sidebar-header-height;
  width: inherit;
  padding: 13px $sidebar-padding;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-up(lg) {
    justify-content: center;
  }
}

.sidebarContent {
  margin-top: $sidebar-header-height;

  @include fullHeight;

  padding: 0 $sidebar-padding;
}

.sidebarCloseButton {
  border: none;
  cursor: pointer;
  padding: calc($grid-gutter-width / 2);
  margin: - calc($grid-gutter-width / 2);
  background-color: transparent;

  svg {
    width: 11px;
    height: 11px;
    color: white;
  }

  .root.docked & {
    display: none;
  }
}
