@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.placeholder,
.root {
  height: 66px;
}

.placeholder {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: $black;

  @include media-breakpoint-up(lg) {
    position: relative;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px $container-padding;
}

.spark {
  position: absolute;
  top: 0;
  right: 0;
  width: 68px;
  height: 52px;
  background-image: url("~nvent-web/assets/png/logo-right.png");
  background-repeat: no-repeat;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.picker {
  z-index: 1;
}
