@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.wrapper {
  flex: 1;
  z-index: 20;
  height: 100%;
  overflow: hidden;
  position: fixed;
  width: 50%;
  top: 0;
  right: 0;
}

.photo {
  height: 100%;
  background-color: $pale-grey;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 30% center;
  position: relative;
  background-image: url("~nvent-web/assets/jpg/hero-background.1000.jpg");

  @media (min-height: 1001px) {
    background-image: url("~nvent-web/assets/jpg/hero-background.1500.jpg");
  }

  @media (min-height: 1501px) {
    background-image: url("~nvent-web/assets/jpg/hero-background.2000.jpg");
  }
}

.spark {
  position: absolute;
  bottom: 0;
  left: -80px;
  width: 432px;
  height: auto;
}

.content {
  max-width: 432px;
  height: 100%;

  @include flexbox(center, flex-start);

  flex-direction: column;
  margin: 0 40px;

  @include media-breakpoint-up(xl) {
    margin: 0 auto;
    max-width: 536px;
  }
}

.title {
  font-size: 75px;
  font-weight: bold;
  line-height: 1.07;
  color: $lipstick;
  margin: 0;
}

.description {
  color: $lipstick;
  font-size: 24px;
  margin: 25px 0 0;
}

.rightIcons {
  position: absolute;
  top: 20px;
  right: 40px;
}

.nventLogo {
  height: 140px;
  width: 350px;
  position: absolute;
  top: 20px;
  right: 0;
}

.certified {
  display: block;
  height: 140px;
  position: absolute;
  top: 200px;
  right: 32px;
}
